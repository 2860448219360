exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #DFA32A;\n  --btn-boxShadow-bg: #000;\n  --btn-bordercolor-bg: #DFA32A;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #DFA32A;\n  --btn-secondary-bg: #ffffff;\n  --btn-secondary-color: #DFA32A;\n  --admin-bg-color: #ffffff;\n  --admin-border-color: #E0E0C6;\n  --admin-text-color: #433415;\n  --admin-textSecondary-color: #DFA32A;\n  --admin-commonBg-color: #FFFFFA;\n  --admin-secondaryText-color: #DFA32A;\n\n}\n/** RentAll - Begin **/\n._3Vqxt {\n\tmax-width: 1024px;\n\tmargin: 54px auto;\n\twidth: 100%;\n\tpadding: 10px;\n}\n.m4o2M {\n\tpadding: 0px;\n\tmargin: 30px 15px;\n}\n._3sO4c{\n\twidth: 100%;\n\ttext-align: center;\n}\n@media screen and (max-width: 767px) {\n\n  ._3Vqxt {\n\t\tmargin: 0px auto;\n\t}\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "_3Vqxt",
	"logInModalBody": "m4o2M",
	"textCenter": "_3sO4c"
};